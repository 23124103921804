.an {
  justify-content: space-between;
  align-items: center;
  border-bottom-style: solid;
  border-bottom-color: #2da884;
  border-bottom-width: 0.01rem;
  padding: 1rem 0rem;
}

.header-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.content-padding_navbar {
  max-width: 1268px;
  margin-left: auto;
  margin-right: auto;
  padding-right: 24px;
  padding-left: 24px;
}

.an-links {
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}

.an-links_logo {
  margin-right: 2rem;
}

.an-links_logo img {
  height: 50px;
}

.an-links_container {
  display: flex;
  flex-direction: row;
}

.an-sign {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.an-links_container p,
.an-sign p,
.an-menu_container p {
  color: #000;
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  text-transform: capitalize;
  margin: 0 1rem;
  cursor: pointer;
}

.an-links_container p:hover {
  color: #2da884;
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  text-transform: capitalize;
  margin: 0 1rem;
  cursor: pointer;
  transition: color 300ms ease, border-color 400ms ease;
}

.an-sign button,
.an-menu_container button {
  padding: 0.5rem 1rem;
  color: #fff;
  background: #2da884;
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  cursor: pointer;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  border: 2px solid #2da884;
  transition: color 300ms ease, border-color 400ms ease;
}

.an-sign button:hover,
.an-menu_container button:hover {
  padding: 0.5rem 1rem;
  color: #2da884;
  border: 2px solid #2da884;
  background: transparent;
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  cursor: pointer;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  transition: color 300ms ease, border-color 400ms ease;
}

.an-menu {
  margin-left: 1rem;
  display: none;
  position: relative;
}

.an-menu svg {
  cursor: pointer;
}

.an-menu_container {
  display: flex;
  z-index: 5;
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: column;
  text-align: end;
  background: var(--color-footer);
  padding: 2rem;
  position: absolute;
  right: 0;
  top: 40px;
  margin-top: 1rem;
  min-width: 210px;
  border-radius: 5px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
}

.an-menu_container p {
  margin: 1rem 0;
}

.an-menu_container-links-sign {
  display: none;
}

@media screen and (max-width: 1050px) {
  .an-links_container {
    display: none;
  }

  .an-menu {
    display: flex;
  }
  .an-sign {
    display: none;
  }
}

@media screen and (max-width: 700px) {
  .an-sign {
    display: none;
  }
}

@media screen and (max-width: 550px) {
  .an-sign {
    display: none;
  }

  .an-menu_container {
    top: 20px;
  }

  .an-menu_container-links-sign {
    display: block;
  }
}
