.portfolio {
  position: relative;
  padding-top: 50px;
  padding-bottom: 50px;
  background-color: rgba(45, 169, 132, 0);
}

.content-padding_portfolio {
  max-width: 1268px;
  margin-left: auto;
  margin-right: auto;
  padding-right: 24px;
  padding-left: 24px;
}

.portfolio-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
}

.portfolio-content h1 {
  font-family: var(--font-family);
  font-weight: 800;
  font-size: 40px;
  line-height: 50px;
  letter-spacing: -0.04em;
  margin-bottom: 20px;
}

.portfolio-grid {
  display: grid;
  grid-auto-columns: 1fr;
  margin-bottom: 48px;
  justify-items: center;
  align-items: center;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  grid-template-columns: 1fr 1fr 2.1fr 1fr;
  grid-template-rows: auto;
}

.portfolio-grid_image {
  display: block;
  width: 100%;
  align-self: center;
  object-fit: cover;
  max-width: 100%;
  overflow-clip-margin: content-box;
  overflow: clip;
}

.three {
  grid-row-start: span 2;
  -ms-grid-row-span: 2;
  grid-row-end: span 2;
  grid-column-start: span 1;
  grid-column-end: span 1;
  align-self: start;
}

.portfolio-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.portfolio-button button {
  flex: 0.4;
  min-height: 50px;
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  background: #2da884;
  border: 2px solid #2da884;
  padding: 0 1rem;
  color: #fff;
  cursor: pointer;
  outline: none;
  border-radius: 5px;
  transition: color 300ms ease, border-color 400ms ease;
}

.portfolio-button button:hover {
  flex: 0.4;
  min-height: 50px;
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  background: transparent;
  border: 2px solid #2da884;
  padding: 0 1rem;
  color: #2da884;
  cursor: pointer;
  outline: none;
  border-radius: 5px;
  transition: color 300ms ease, border-color 400ms ease;
}

@media screen and (max-width: 750px) {
  .portfolio-grid {
    margin-bottom: 32px;
    grid-template-columns: 1fr 1fr 1fr;
  }
  .three {
    grid-row-start: span 1;
    grid-row-end: span 1;
    grid-column-start: span 1;
  }

  .imageDisappear {
    display: none;
  }

  .portfolio-content {
    align-items: center;
  }

  .portfolio-content h1 {
    font-size: 25px;
  }

  .portfolio-button button {
    font-size: 16px;
    line-height: 24px;
  }

  .portfolio-button button:hover {
    font-size: 16px;
    line-height: 24px;
  }
}

@media screen and (max-width: 490px) {
  .portfolio-button button {
    font-size: 12px;
    line-height: 16px;
  }

  .portfolio-button button:hover {
    font-size: 12px;
    line-height: 16px;
  }
  .portfolio {
    padding-top: 0px;
  }
}
