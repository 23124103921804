.call {
  overflow: hidden;
  padding-top: 60px;
  padding-bottom: 60px;
  background-color: rgba(45, 169, 132, 0.05);
}

.content-padding_call {
  max-width: 1268px;
  margin-left: auto;
  margin-right: auto;
  padding-right: 24px;
  padding-left: 24px;
}

.call-content {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  align-items: center;
}

.call-content h1 {
  font-family: var(--font-family);
  font-weight: 800;
  font-size: 40px;
  line-height: 75px;
  letter-spacing: -0.04em;
  margin-bottom: 20px;
  text-align: center;
}

.call-content h3 {
  font-family: var(--font-family);
  font-weight: 800;
  font-size: 55px;
  line-height: 75px;
  letter-spacing: -0.04em;
  color: #2da884;
  text-align: center;
}

.call-img-leaf {
  right: 0px;
  top: -30px;
  z-index: 0;
  max-width: 40%;
  position: absolute;
  display: block;
}

.call-wrapper {
  will-change: opacity, transform;
  opacity: 1;
  transform: translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg)
    rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
  transform-style: preserve-3d;
  width: 100%;
  height: 100%;
}

.call-split {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media screen and (max-width: 650px) {
  .call-content h1 {
    font-size: 25px;
    line-height: 48px;
  }

  .call-content h3 {
    font-size: 30px;
    line-height: 50px;
  }

  .call-content p {
    font-size: 14px;
    line-height: 24px;
  }
}
