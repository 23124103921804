.services {
  padding-top: 180px;
  padding-bottom: 50px;
  background-color: rgba(45, 169, 132, 0);
}

.content-padding_services {
  max-width: 1268px;
  margin-left: auto;
  margin-right: auto;
  padding-right: 24px;
  padding-left: 24px;
}

.services-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  height: 100%;
}

.services-content h1 {
  font-family: var(--font-family);
  font-weight: 800;
  font-size: 40px;
  line-height: 50px;
  letter-spacing: -0.04em;
  margin-bottom: 20px;
}

.services-content h3 {
  font-family: var(--font-family);
  font-weight: 800;
  font-size: 25px;
  line-height: 75px;
  letter-spacing: -0.04em;
  color: #2da884;
}

.services-content p {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  color: var(--color-text);
}

.services-wrapper {
  align-items: stretch;
  display: flex;
  justify-content: space-between;
  position: relative;
}

.services-split_left {
  width: 100%;
  max-width: 30%;
}

.services-sticky-container {
  height: 100%;
}

.services-split_right {
  width: 100%;
  max-width: 65%;
}

.services-sticky_left {
  position: sticky;
  top: 24px;
  margin-top: -30px;
  border-style: none;
  border-width: 1px;
  border-color: #d9e9e5;
  background-color: #fff;
}

.services-grid {
  display: grid;
  grid-auto-columns: 1fr;
  grid-column-gap: 26px;
  grid-row-gap: 26px;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
}

.services-grid_item {
  will-change: opacity, transform;
  opacity: 1;
  transform: translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg)
    rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
  transform-style: preserve-3d;
  width: 100%;
  height: 100%;
}

.services-grid_item_ref {
  transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1.01) rotateX(0deg)
    rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
  transform-style: preserve-3d;
  overflow: hidden;
  border-style: solid;
  border-width: 1px;
  border-color: #d9e9e5;
  background-color: #fff;
  height: 100%;
  color: #637a75;
  text-decoration: none;
  max-width: 100%;
  display: inline-block;
}

.services-grid_item_img {
  position: relative;
  overflow: hidden;
  width: 100%;
}

.services-grid_item_img img {
  position: relative;
  z-index: 2;
  display: block;
  width: 100%;
  filter: blur(0px);
  max-width: 100%;
}

.services-grid_item_content {
  position: relative;
  z-index: 10;
  padding: 40px 40px 48px;
  background-color: #fff;
}

.services-grid_item_content h1 {
  font-family: var(--font-family);
  font-weight: 800;
  font-size: 30px;
  line-height: 50px;
  letter-spacing: -0.04em;
  margin-bottom: 20px;
}

.services-grid_item_content p {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  color: var(--color-text);
}

.services-button {
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  margin-top: 30px;
}

.services-button button {
  flex: 0.4;
  min-height: 50px;
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  background: transparent;
  border: 2px solid #2da884;
  padding: 0 1rem;
  color: #2da884;
  cursor: pointer;
  outline: none;
  border-radius: 5px;
  transition: color 300ms ease, border-color 400ms ease;
}

.services-button button:hover {
  flex: 0.4;
  min-height: 50px;
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  background: #2da884;
  border: 2px solid #2da884;
  padding: 0 1rem;
  color: #fff;
  cursor: pointer;
  outline: none;
  border-radius: 5px;
  transition: color 300ms ease, border-color 400ms ease;
}

@media screen and (max-width: 1050px) {
  .services {
    padding-top: 50px;
  }
  .services-wrapper {
    display: block;
  }

  .services-split_left {
    max-width: 100%;
    margin-bottom: 50px;
  }

  .services-split_right {
    max-width: 100%;
  }
}

@media screen and (max-width: 750px) {
  .services-grid {
    display: block;
  }

  .services-grid_item {
    padding-bottom: 30px;
  }
}

@media screen and (max-width: 650px) {
  .services-grid_item_content p {
    font-size: 16px;
    line-height: 24px;
  }

  .services-button button {
    font-size: 16px;
    line-height: 24px;
  }

  .services-button button:hover {
    font-size: 16px;
    line-height: 24px;
  }
}

@media screen and (max-width: 490px) {
  .services-content h1 {
    font-size: 34px;
    line-height: 48px;
  }

  .services-content p {
    font-size: 14px;
    line-height: 24px;
  }

  .services-grid_item_content h1 {
    font-size: 28px;
    line-height: 48px;
  }

  .services-grid_item_content p {
    font-size: 14px;
    line-height: 24px;
  }

  .services-button button {
    font-size: 12px;
    line-height: 16px;
  }

  .services-button button:hover {
    font-size: 12px;
    line-height: 16px;
  }
}
