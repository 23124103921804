.header {
  position: relative;
  display: flex;
  overflow: hidden;
  height: 740px;
  min-height: 72vh;
  padding-top: 0px;
  padding-bottom: 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.header-content {
  position: absolute;
  left: 0px;
  right: 0px;
}

.header-content_grid {
  display: grid;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
}

.header-content_left {
  grid-column-end: 2;
  grid-column-start: 1;
  grid-row-end: 2;
  grid-row-start: 1;
  max-width: 95%;
}

.content-padding_header {
  max-width: 1268px;
  margin-right: auto;
  margin-left: auto;
  padding-right: 24px;
  padding-left: 24px;
}

.header-content h1 {
  font-family: var(--font-family);
  font-weight: 800;
  font-size: 62px;
  line-height: 75px;
  letter-spacing: -0.04em;
}

.header-content h3 {
  font-family: var(--font-family);
  font-weight: 800;
  font-size: 25px;
  line-height: 75px;
  letter-spacing: -0.04em;
  color: #2da884;
}

.header-content p {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  color: var(--color-text);

  margin-top: 1.5rem;
}

.header-content__input {
  width: 100%;
  margin: 2rem 0 1rem;
  display: flex;
  flex-direction: row;
}

.button {
  flex: 0.6;
  width: 100%;
  min-height: 50px;
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  background: #2da884;
  border: 2px solid #2da884;
  padding: 0 1rem;
  color: #fff;
  cursor: pointer;
  outline: none;
  border-radius: 5px;
  margin-right: 1.2rem;
  transition: color 300ms ease, border-color 400ms ease;
}

.button:hover {
  flex: 0.6;
  width: 100%;
  min-height: 50px;
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  background: transparent;
  border: 2px solid #2da884;
  padding: 0 1rem;
  color: #2da884;
  cursor: pointer;
  outline: none;
  border-radius: 5px;
  margin-right: 1.2rem;
  transition: color 300ms ease, border-color 400ms ease;
}

.button2 {
  flex: 0.6;
  width: 100%;
  min-height: 50px;
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  background: transparent;
  border: 2px solid #2da884;
  padding: 0 1rem;
  color: #2da884;
  cursor: pointer;
  outline: none;
  border-radius: 5px;
  margin-left: 1.2rem;
  margin-right: 1.2rem;
  transition: color 300ms ease, border-color 400ms ease;
}

.button2:hover {
  flex: 0.6;
  width: 100%;
  min-height: 50px;
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  background: #2da884;
  border: 2px solid #2da884;
  padding: 0 1rem;
  color: #fff;
  cursor: pointer;
  outline: none;
  border-radius: 5px;
  margin-left: 1.2rem;
  margin-right: 1.2rem;
  transition: color 300ms ease, border-color 400ms ease;
}

.leaf {
  position: absolute;
  display: block;
}

.leaf {
  left: -18px;
  top: 0;
  right: auto;
  bottom: auto;
  z-index: -1;
  opacity: 1;
  width: 29%;
  max-width: 420px;
}

.header-image {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  max-width: 50%;
  align-items: stretch;
  align-self: flex-end;
  flex: 1;
}

.header-image img {
  position: absolute;
  left: auto;
  top: 0%;
  right: 0%;
  bottom: 0%;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 100% 50%;
  max-width: 95%;
  vertical-align: middle;
}

.background_transfrom {
  transform: translate3d(0px, 10px, 0px) scale3d(0, 0, 0) rotateX(0deg)
    rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
  opacity: 0;
  transform-style: preserve-3d;
  transition: opacity 500ms ease-in-out;
}

.background_transfrom.fade-in {
  transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg)
    rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
  opacity: 1;
  transform-style: preserve-3d;
}

.short_transform {
  transform: translate3d(0px, 10px, 0px) scale3d(0, 0, 0) rotateX(0deg)
    rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
  opacity: 0;
  transform-style: preserve-3d;
  transition: opacity 500ms ease-in-out;
}

.short_transform.fade-in {
  transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg)
    rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
  opacity: 1;
  transform-style: preserve-3d;
}

.medium_transform {
  transform: translate3d(0px, 10px, 0px) scale3d(0, 0, 0) rotateX(0deg)
    rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
  opacity: 0;
  transform-style: preserve-3d;
  transition: opacity 550ms ease-in-out;
}

.medium_transform.fade-in {
  transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg)
    rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
  opacity: 1;
  transform-style: preserve-3d;
}

.long_transform {
  transform: translate3d(0px, 10px, 0px) scale3d(0, 0, 0) rotateX(0deg)
    rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
  opacity: 0;
  transform-style: preserve-3d;
  transition: opacity 700ms ease-in-out;
}

.long_transform.fade-in {
  transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg)
    rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
  opacity: 1;
  transform-style: preserve-3d;
}

@media screen and (max-width: 1050px) {
  .header {
    height: auto;
    min-height: auto;
    padding-top: 40px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
  }

  .header-content {
    position: static;
  }

  .header-image {
    position: relative;
    z-index: -1;
    overflow: hidden;
    max-width: 100%;
    min-height: 420px;
  }
  .header-image img {
    position: absolute;
    left: 0%;
    top: 0%;
    right: 0%;
    bottom: 0%;
    max-width: 100%;
    object-position: 50% 18%;
  }

  .header-content_grid {
    display: block;
    margin-bottom: 40px;
  }
  .leaf {
    top: 10px;
  }
}

@media screen and (max-width: 650px) {
  .header-content h1 {
    font-size: 48px;
    line-height: 60px;
  }

  .header-content p {
    font-size: 16px;
    line-height: 24px;
  }

  .header-content__people {
    flex-direction: column;
  }

  .header-content__people p {
    margin: 0;
  }

  .header-content__input button {
    font-size: 16px;
    line-height: 24px;
  }
  .header-content__input button:hover {
    font-size: 16px;
    line-height: 24px;
  }
}

@media screen and (max-width: 490px) {
  .header-content h1 {
    font-size: 36px;
    line-height: 48px;
  }

  .header-content p {
    font-size: 14px;
    line-height: 24px;
  }

  .header-content__input input,
  .header-content__input button {
    font-size: 12px;
    line-height: 16px;
  }

  .header-content__input button:hover {
    font-size: 12px;
    line-height: 16px;
  }
}
