.quote {
  position: relative;
  overflow: hidden;
  min-height: 72vh;
  padding-top: 0px;
  padding-bottom: 0px;
  justify-content: center;
  align-items: center;
}

.error {
  color: red;
  font-family: var(--font-family);
  font-weight: 800;
  font-size: 12px;
  text-align: center;
}

.content-padding_quote {
  max-width: 1268px;
  margin-left: auto;
  margin-right: auto;
  padding-right: 24px;
  padding-left: 24px;
}

.quote-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.quote-content h3 {
  font-family: var(--font-family);
  font-weight: 800;
  font-size: 25px;
  line-height: 75px;
  letter-spacing: -0.04em;
  color: #2da884;
  text-align: center;
}

.quote-content h1 {
  font-family: var(--font-family);
  font-weight: 800;
  font-size: 40px;
  line-height: 50px;
  letter-spacing: -0.04em;
  margin-bottom: 20px;
  text-align: center;
}

.quote-content p {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 15px;
  line-height: 28px;
  color: var(--color-text);
  text-align: center;
}

.quote-input {
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.quote-name_fields {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.quote-input input,
.quote-name_fields input {
  max-width: 60%;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 0.5rem 0.3rem;
  margin-bottom: 2rem;
  outline-color: #2da884;
  font-family: var(--font-family);
}

.quote-input textarea {
  max-width: 60%;
  width: 100%;
  height: auto;
  min-height: 10rem;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 0.5rem 0.3rem;
  margin-bottom: 2rem;
  outline-color: #2da884;
  font-family: var(--font-family);
}

.quote-input_split_left {
  width: 100%;
  justify-content: center;
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  padding-right: 0 0.3rem;
}

.quote-input_split_right {
  width: 100%;
  justify-content: center;
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  padding-left: 0.3rem;
}

.quote-title_left {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 60%;
}

.quote-form {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.quote-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 30px;
}

.quote-button button {
  flex: 0.4;
  min-height: 50px;
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  background: #2da884;
  border: 2px solid #2da884;
  padding: 0 1rem;
  color: #fff;
  cursor: pointer;
  outline: none;
  border-radius: 5px;
  transition: color 300ms ease, border-color 400ms ease;
}

.quote-button button:hover {
  flex: 0.4;
  min-height: 50px;
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  background: transparent;
  border: 2px solid #2da884;
  padding: 0 1rem;
  color: #2da884;
  cursor: pointer;
  outline: none;
  border-radius: 5px;
  transition: color 300ms ease, border-color 400ms ease;
}

.quote-background_transfrom {
  transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg)
    rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
  opacity: 1;
  transform-style: preserve-3d;
}

.quote-leaf {
  position: absolute;
  display: block;
  left: -18px;
  top: 0;
  right: auto;
  bottom: auto;
  z-index: -1;
  opacity: 1;
  width: 29%;
  max-width: 420px;
}

@media screen and (max-width: 650px) {
  .quote-input input,
  .quote-name_fields input {
    max-width: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    padding: 0.5rem 0.3rem;
    margin-bottom: 2rem;
    outline-color: #2da884;
    font-family: var(--font-family);
  }
  .quote-input textarea {
    max-width: 100%;
    width: 100%;
    height: auto;
    min-height: 10rem;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    padding: 0.5rem 0.3rem;
    margin-bottom: 2rem;
    outline-color: #2da884;
    font-family: var(--font-family);
  }

  .quote-title_left {
    width: 100%;
  }

  .quote-content h1 {
    font-size: 30px;
    line-height: 48px;
  }

  .quote-content h3 {
    font-size: 20px;
    line-height: 50px;
  }

  .quote-content p {
    font-size: 14px;
    line-height: 24px;
  }

  .quote-button button {
    font-size: 17px;
    line-height: 16px;
  }

  .quote-button button:hover {
    font-size: 17px;
    line-height: 16px;
  }
}
