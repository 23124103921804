.description {
  position: relative;
  overflow: hidden;
  min-height: 72vh;
  padding-top: 0px;
  padding-bottom: 0px;
  justify-content: center;
  align-items: center;
}

.content-padding_description {
  max-width: 1268px;
  margin-left: auto;
  margin-right: auto;
  padding-right: 24px;
  padding-left: 24px;
}

.description-content {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}

.description-content p {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  color: var(--color-text);
  margin-top: 1.5rem;
}
