
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap');

:root {
  --font-family: 'Manrope', sans-serif;

  --gradient-text: linear-gradient(89.97deg, #000 1.84%, #000 102.67%);
  --gradient-bar: linear-gradient(103.22deg, #000 -13.86%, #000 99.55%);
  
  --color-bg: #fff;
  --color-footer : #fff;
  --color-blog: #000;
  --color-text: #000;
  --color-subtext: #000;
}