.aboutus {
  overflow: hidden;
  padding-top: 30px;
  padding-bottom: 50px;
  background-color: rgba(45, 169, 132, 0.05);
}

.content-padding_aboutus {
  max-width: 1268px;
  margin-left: auto;
  margin-right: auto;
  padding-right: 24px;
  padding-left: 24px;
}

.aboutus-content {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}

.aboutus-content h1 {
  font-family: var(--font-family);
  font-weight: 800;
  font-size: 62px;
  line-height: 75px;
  letter-spacing: -0.04em;
  margin-bottom: 20px;
}

.aboutus-content h3 {
  font-family: var(--font-family);
  font-weight: 800;
  font-size: 25px;
  line-height: 75px;
  letter-spacing: -0.04em;
  color: #2da884;
}

.aboutus-content p {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  color: var(--color-text);
}

.aboutus-wrapper {
  will-change: opacity, transform;
  opacity: 1;
  transform: translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg)
    rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
  transform-style: preserve-3d;
  width: 100%;
  height: 100%;
}

.aboutus-split {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.aboutus-split-left {
  position: relative;
  display: flex;
  width: 100%;
  max-width: 55%;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.aboutus-split-right {
  width: 100%;
  max-width: 40%;
}

.aboutus-img-one {
  position: relative;
  z-index: 2;
  width: 78%;
  max-width: 520px;
  box-shadow: 0 8px 50px 0 rgba(20, 43, 37, 0.1);
  vertical-align: middle;
  display: inline-block;
}

.aboutus-img-two {
  position: relative;
  z-index: 5;
  margin-top: 32%;
  margin-left: -334px;
  box-shadow: 0 8px 71px 0 rgba(20, 43, 37, 0.25);
  width: 78%;
  max-width: 520px;
}

.aboutus-img-leaf {
  left: 350px;
  top: 110px;
  z-index: 1;
  max-width: 40%;
  position: absolute;
  display: block;
}

.aboutus-grid {
  width: 100%;
  margin-bottom: 40px;
  grid-auto-columns: auto;
  grid-template-columns: auto auto;
  display: grid;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  margin-top: 20px;
}

.aboutus-feature {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.aboutus-img {
  max-width: 100%;
  vertical-align: middle;
  display: inline-block;
  margin-right: 16px;
}

.aboutus-button button {
  flex: 0.6;
  min-height: 50px;
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  background: #2da884;
  border: 2px solid #2da884;
  padding: 0 3rem;
  color: #fff;
  cursor: pointer;
  outline: none;
  border-radius: 5px;
  transition: color 300ms ease, border-color 400ms ease;
}

.aboutus-button button:hover {
  flex: 0.6;
  min-height: 50px;
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  background: transparent;
  border: 2px solid #2da884;
  padding: 0 3rem;
  color: #2da884;
  cursor: pointer;
  outline: none;
  border-radius: 5px;
  transition: color 300ms ease, border-color 400ms ease;
}

@media screen and (max-width: 1000px) {
  .aboutus {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .aboutus-split {
    display: block;
  }

  .aboutus-split-left {
    max-width: 100%;
  }

  .aboutus-split-right {
    max-width: 100%;
  }
}

@media screen and (max-width: 650px) {
  .aboutus-content h1 {
    font-size: 48px;
    line-height: 60px;
  }

  .aboutus-content p {
    font-size: 16px;
    line-height: 24px;
  }

  .aboutus-content__people {
    flex-direction: column;
  }

  .aboutus-content__people p {
    margin: 0;
  }

  .aboutus-button button {
    font-size: 16px;
    line-height: 24px;
  }

  .aboutus-button button:hover {
    font-size: 16px;
    line-height: 24px;
  }
}

@media screen and (max-width: 490px) {
  .aboutus-content h1 {
    font-size: 36px;
    line-height: 48px;
  }

  .aboutus-content p {
    font-size: 14px;
    line-height: 24px;
  }

  .aboutus-button button {
    font-size: 12px;
    line-height: 16px;
  }

  .aboutus-button button:hover {
    font-size: 12px;
    line-height: 16px;
  }
}
