.jobssingle {
  overflow: hidden;
  padding-top: 10px;
  padding-bottom: 50px;
}

.content-padding_jobssingle {
  max-width: 1268px;
  margin-left: auto;
  margin-right: auto;
  padding-right: 24px;
  padding-left: 24px;
}

.jobssingle-content {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}

.jobssingle-content h1 {
  font-family: var(--font-family);
  font-weight: 800;
  font-size: 30px;
  letter-spacing: -0.04em;
  margin-bottom: 10px;
}

.jobssingle-content h2 {
  font-family: var(--font-family);
  font-weight: 800;
  font-size: 20px;
  letter-spacing: -0.04em;
  margin-bottom: 5px;
}

.jobssingle-content h3 {
  font-family: var(--font-family);
  font-weight: 800;
  font-size: 25px;
  line-height: 40px;
  letter-spacing: -0.04em;
  color: #2da884;
}

.jobssingle-content p {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 15px;
  line-height: 28px;
  color: var(--color-text);
}

.jobssingle-card {
  flex: 1;
  width: 100%;
  height: 100%;
  min-height: 120px;
}

.jobssingle-quotes {
  width: 100%;
}

.jobssingle-search {
  display: flex;
  flex-direction: row;
  padding-top: 10px;
  padding-bottom: 20px;
}

.jobssingle-search input {
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 0.2rem 0.3rem;
  outline-color: #2da884;
  font-family: var(--font-family);
}

.jobssingle-search button {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 10px;
  background: #2da884;
  border: 2px solid #2da884;
  padding: 0 1rem;
  color: #fff;
  cursor: pointer;
  outline: none;
  border-radius: 5px;
  margin-left: 1rem;
}

.jobssingle-form {
  flex: 1;
  display: flex;
  width: 100%;
  flex-direction: column;
}

.jobssingle-form input {
  max-width: 300px;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 0.1rem 0.1rem;
  margin-bottom: 0.3rem;
  outline-color: #2da884;
  font-family: var(--font-family);
}

.jobssingle-address {
  width: 100%;
}

.jobssingle-form button {
  max-width: 300px;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 0.1rem 0.1rem;
  margin-bottom: 0.3rem;
  font-family: var(--font-family);
  min-height: 10px;
  font-weight: 400;
  background: #2da884;
  border: 2px solid #2da884;
  color: #fff;
  cursor: pointer;
  outline: none;
  border-radius: 5px;
}

.jobssingle-images {
  flex-direction: column;
  display: flex;
  width: 100%;
}

.js-notes_content-2 button,
.jobssingle-images button {
  max-width: 150px;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 0.3rem 0.3rem;
  margin-bottom: 0.3rem;
  font-family: var(--font-family);
  min-height: 10px;
  font-weight: 400;
  background: #2da884;
  border: 2px solid #2da884;
  color: #fff;
  cursor: pointer;
  outline: none;
  border-radius: 5px;
}

.js-images_button {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

.js-slider {
  position: relative;
  display: flex;
  align-items: center;
  margin-top: 0rem;
  margin-bottom: 2rem;
  min-height: 200px;
}

.js-slider_icon {
  opacity: 0.5;
  cursor: pointer;
  color: #2da884;
}

.js-slider_icon:hover {
  opacity: 1;
}

.js-slider_content {
  width: 100%;
  height: 100%;
  overflow: scroll;
  white-space: nowrap;
}

.js-slider_content_image {
  width: 300px;
  height: 300px;
  display: inline-block;
  cursor: pointer;
  object-fit: contain;
  background-color: transparent;
  margin-right: 5px;
  margin-left: 5px;
}

.js-slider_content_image:hover {
  transform: scale(1.05);
  transition: 300ms ease-in-out;
}

.js-top_buttons {
  display: flex;
  flex-direction: row;
  flex: 1;
  width: 100%;
  justify-content: center;
}

.js-delete {
  justify-content: center;
  align-items: flex-end;
}

.js-delete button {
  max-width: 150px;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 0.3rem 0.3rem;
  margin-bottom: 0.3rem;
  font-family: var(--font-family);
  min-height: 10px;
  font-weight: 400;
  background: transparent;
  border: 2px solid red;
  color: red;
  cursor: pointer;
  outline: none;
  border-radius: 5px;
}

.js-delete button:hover {
  max-width: 150px;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 0.3rem 0.3rem;
  margin-bottom: 0.3rem;
  font-family: var(--font-family);
  min-height: 10px;
  font-weight: 400;
  background: red;
  border: 2px solid red;
  color: #fff;
  cursor: pointer;
  outline: none;
  border-radius: 5px;
}

.js-goback {
  display: flex;
  flex: 1;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
}

.js-title_left {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
}

.js-notes {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.js-form {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.js-notes_content {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.js-notes_content-2 {
  width: 100%;
  max-width: 500px;
  justify-content: center;
  align-items: center;
}

.js-notes_content-2 input {
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 0.3rem 0.3rem;
  margin-bottom: 2rem;
  outline-color: #2da884;
  font-family: var(--font-family);
}

.js-notes_content-2 textarea {
  width: 100%;
  height: auto;
  min-height: 8rem;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 0.5rem 0.3rem;
  margin-bottom: 2rem;
  outline-color: #2da884;
  font-family: var(--font-family);
}

.js-notes_item {
  padding-bottom: 10px;
}

.js-notes_item h4 {
  font-family: var(--font-family);
  font-weight: 800;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.04em;
  color: #2da884;
}

.js-quote {
  display: flex;
  width: 100%;
  margin-top: 30px;
  flex-direction: column;
}

.js-quote_grid {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 4px;
  justify-items: center;
  align-items: center;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
}

.js-quote_price input {
  max-width: 300px;
  width: 100%;
  font-size: 20px;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 0.1rem 0.1rem;
  outline-color: #2da884;
  margin-bottom: 2rem;
  font-family: var(--font-family);
}

.js-quote_button {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2px;
}

.js-quote_button button {
  max-width: 300px;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 0.1rem 0.1rem;
  margin-bottom: 0.3rem;
  font-family: var(--font-family);
  min-height: 40px;
  font-weight: 800;
  background: transparent;
  border: 2px solid #2da884;
  color: #2da884;
  cursor: pointer;
  outline: none;
  border-radius: 5px;
}

.js-delete_conf {
  display: flex;
  flex-direction: row;
}

.js-delete_conf p {
  font-size: 11px;
  padding-left: 5px;
}
