.enquiry {
  overflow: hidden;
  padding-top: 30px;
  padding-bottom: 50px;
}

.content-padding_enquiry {
  max-width: 1268px;
  margin-left: auto;
  margin-right: auto;
  padding-right: 24px;
  padding-left: 24px;
}

.enquiry-content {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}

.enquiry-content h1 {
  font-family: var(--font-family);
  font-weight: 800;
  font-size: 30px;
  letter-spacing: -0.04em;
  margin-bottom: 10px;
}

.enquiry-content h3 {
  font-family: var(--font-family);
  font-weight: 800;
  font-size: 25px;
  line-height: 40px;
  letter-spacing: -0.04em;
  color: #2da884;
}

.enquiry-content p {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 15px;
  line-height: 28px;
  color: var(--color-text);
}

.enquiry-card {
  flex: 1;
  border: 1.5px solid #2da884;
  border-radius: 5px;
  width: 100%;
  height: 100%;
  min-height: 120px;
  margin-bottom: 2rem;
  padding: 10px;
  cursor: pointer;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.enquiry-quotes {
  width: 100%;
}

.enquiry-search {
  display: flex;
  flex-direction: row;
  padding-top: 10px;
  padding-bottom: 20px;
}

.enquiry-search input {
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 0.2rem 0.3rem;
  outline-color: #2da884;
  font-family: var(--font-family);
}

.enquiry-search button {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 10px;
  background: #2da884;
  border: 2px solid #2da884;
  padding: 0 1rem;
  color: #fff;
  cursor: pointer;
  outline: none;
  border-radius: 5px;
  margin-left: 1rem;
}
