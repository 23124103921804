.why {
  padding-bottom: 50px;
  background-color: rgba(45, 169, 132, 0);
}

.content-padding_why {
  max-width: 1268px;
  margin-left: auto;
  margin-right: auto;
  padding-right: 24px;
  padding-left: 24px;
}

.why-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  border-top-style: solid;
  border-top-color: #2da884;
  border-top-width: 0.01rem;
  border-bottom-style: solid;
  border-bottom-color: #2da884;
  border-bottom-width: 0.01rem;
  padding-top: 50px;
  padding-bottom: 80px;
}

.why-content h3 {
  font-family: var(--font-family);
  font-weight: 800;
  font-size: 25px;
  line-height: 75px;
  letter-spacing: -0.04em;
  color: #2da884;
}

.why-content h1 {
  font-family: var(--font-family);
  font-weight: 800;
  font-size: 40px;
  line-height: 50px;
  letter-spacing: -0.04em;
  margin-bottom: 20px;
  text-align: center;
}

.why-content p {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  color: var(--color-text);
  text-align: center;
}

.why-grid {
  display: flex;
  justify-content: space-between;
  grid-auto-columns: auto;
  grid-template-rows: auto;
  margin-top: 50px;
}

.why-grid_item {
  display: flex;
  overflow: visible;
  width: 100%;
  max-width: 200px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-style: none;
  text-align: center;
  padding: 20px;
}

.why-text {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.text {
  font-family: var(--font-family);
  font-weight: 800;
  font-size: 40px;
  line-height: 50px;
  letter-spacing: -0.04em;
}

.icon {
  font-family: var(--font-family);
  font-weight: 800;
  font-size: 40px;
  line-height: 50px;
  letter-spacing: -0.04em;
  color: #2da884;
}

.why-grid_item h2 {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  color: var(--color-text);
  text-align: center;
}

@media screen and (max-width: 880px) {
  .why-grid {
    display: grid;
    margin-right: auto;
    margin-left: auto;
    justify-items: center;
    align-items: start;
    grid-auto-columns: 1fr;
    grid-column-gap: 100px;
    grid-row-gap: 60px;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    margin-top: 50px;
  }
}

@media screen and (max-width: 650px) {
  .why-content h1 {
    font-size: 30px;
    line-height: 35px;
  }

  .why-content p {
    font-size: 16px;
    line-height: 24px;
  }

  .why-grid {
    grid-column-gap: 0.1rem;
  }
}

@media screen and (max-width: 490px) {
  .why-content h1 {
    font-size: 30px;
    line-height: 48px;
  }

  .why-content p {
    font-size: 14px;
    line-height: 24px;
  }
}
