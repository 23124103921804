.is-slider {
  position: relative;
  display: flex;
  align-items: center;
  margin-top: 0rem;
  margin-bottom: 2rem;
  min-height: 200px;
}

.is-slider_icon {
  opacity: 0.5;
  cursor: pointer;
  color: #2da884;
}

.is-slider_icon:hover {
  opacity: 1;
}

.is-slider_content {
  width: 100%;
  height: 100%;
  overflow: scroll;
  white-space: nowrap;
}

.is-slider_content_image {
  height: 300px;
  display: inline-block;
  cursor: pointer;
  object-fit: contain;
  background-color: transparent;
  margin-right: 5px;
  margin-left: 5px;
}

.is-slider_content_image:hover {
  transform: scale(1.05);
  transition: 300ms ease-in-out;
}

.is-images_button {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

.is-images {
  flex-direction: column;
  display: flex;
  width: 100%;
}

.is-images button {
  max-width: 150px;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 0.3rem 0.3rem;
  margin-bottom: 0.3rem;
  font-family: var(--font-family);
  min-height: 10px;
  font-weight: 400;
  background: #2da884;
  border: 2px solid #2da884;
  color: #fff;
  cursor: pointer;
  outline: none;
  border-radius: 5px;
}
