.login {
  position: relative;
  display: flex;
  overflow: hidden;
  height: 740px;
  min-height: 72vh;
  padding-top: 0px;
  padding-bottom: 0px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.login-content {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}

.content-padding {
  max-width: 600px;
  margin-right: auto;
  margin-left: auto;
  padding-right: 24px;
  padding-left: 24px;
}

.login-form {
  display: flex;
  flex-direction: column;
}
